import { graphql, useStaticQuery } from 'gatsby'

export const usePapers = () => {
  const data = useStaticQuery(graphql`
    query {
      papers: sanityPapers {
        title
        seo {
          title
          description
          image {
            asset {
              url
            }
          }
        }
        _rawContent
        _rawHubspotScript
        filePreview {
          displayName,
          document {
            asset {
              url,
              _id,
              originalFilename
            }
          }
        }
      }
    }
  `)
  return data || {}
}
